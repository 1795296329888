//Metropolis Font Version that we use: r8 https://github.com/chrismsimpson/Metropolis/releases/tag/r8

@font-face {
    font-family: "Metropolis";
    src: url('./fonts/Metropolis-Light.woff') format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Metropolis";
    src: url('./fonts/Metropolis-Regular.woff') format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Metropolis";
    src: url('./fonts/Metropolis-Medium.woff') format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Metropolis";
    src: url('./fonts/Metropolis-SemiBold.woff') format("woff");
    font-weight: 600;
    font-style: normal;
}
